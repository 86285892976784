import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blankslate',
  templateUrl: './blankslate.component.html',
  styleUrls: ['./blankslate.component.scss']
})
export class BlankslateComponent implements OnInit {

  @Input() title?: HTMLElement | any; 
  @Input() subtitle?: string;
  @Input() imageUrl?: string;
  @Input() imageAlt?: string;
  @Input() imageWidth?: string; 
  @Input() buttonAction?: string;
  @Input() buttonName?: string; 

  

  constructor() { }

  ngOnInit() {
  }

}
