import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-presentation-screen',
  templateUrl: './presentation-screen.component.html',
  styleUrls: ['./presentation-screen.component.scss'],
})
export class PresentationScreenComponent implements OnInit {
  constructor(public router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['origin_campaign']) {
        window.localStorage.setItem(
          'origin_campaign',
          params['origin_campaign']
        );
      }
      if (params['origin_lead']) {
        window.localStorage.setItem('origin_lead', params['origin_lead']);
      }
    });
  }

  navigatePreRegister() {
    this.router.navigate(['./register'], { relativeTo: this.activatedRoute });
  }
}
