import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isEdit: boolean = false;
  title = 'register-front';
  teste = `Parabéns, seu <br>cadastro foi finalizado com sucesso</br> e agora nosso time esta analisando seus dados para podermos liberar seu acesso na plataforma.`;

  ngOnInit() {
    this.validUrl();
  }
  validUrl(): void {
    const url = window.location.href;
    url.split('/').includes('edit') ? this.isEdit = true : this.isEdit = false
  }
}
