import { Component, OnInit, AfterViewInit, EventEmitter, Input, Output, ViewChild, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { BankList } from 'src/app/utils/bank-list';

export interface Bank {
  COMPE: string,
  ISPB: string,
  Document: string,
  LongName: string,
  ShortName: string,
  Network: string,
  Type: string,
  PixType: string,
  Url: string,
  DateOperationStarted: string,
  DatePixStarted: string,
  DateRegistered:string,
  DateUpdated: string
}
@Component({
  selector: 'app-select-bank',
  templateUrl: './select-bank.component.html',
  styleUrls: ['./select-bank.component.scss']
})
export class SelectBankComponent implements OnInit, AfterViewInit {

  constructor() {}

  @Input() code?: string;
  @Input() label?: string;
  @Input() disabled: boolean = false;
  @Output() selectionChange = new EventEmitter<Bank | any>();

  protected banks?: Bank[] | any = BankList.list();
  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect?: MatSelect | any;

  protected _onDestroy = new Subject<void>();



  ngOnInit() {
    if (this.code) {
      const id = this.banks?.filter((bank: Bank) => bank.COMPE === this.code)[0]
      this.bankCtrl.setValue(id);
    }

    this.filteredBanks.next(this.banks?.slice());

    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });


  }

  ngAfterViewInit() {
    this.setInitialValue();

    this.bankCtrl.valueChanges.subscribe(bank => {
      this.selectionChange.emit(bank)
    })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: Bank, b: Bank) => a && b && a.COMPE === b.COMPE;
      });
  }

  protected filterBanks() {
    if (!this.banks) return; 
    let search = this.bankFilterCtrl.value;
    
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredBanks.next(
      this.banks.filter((bank: Bank) => bank.LongName.toLowerCase().indexOf(search) > -1 || bank.COMPE.toLowerCase().indexOf(search) > -1 )
    );
  }

}
