import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormatter'
})
export class PhoneFormatterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value) {
  
      var RegExp = /^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/;
      var formattedNumber = value.replace(RegExp, "($1) $2-$3")
      
      return formattedNumber;
    }
  }

}
